



























































































































































































































import { Component, Vue } from "vue-property-decorator";
import { Util } from "@/common/Views/comm/util";
import { BasicsClass } from "@/common/BasicsClass";
import axios from 'axios';
import dpMap from './modules/bigScreenMap.vue';
import barEchartDate from './modules/barEchartDate.vue';
import pieEchart from './modules/pieEchart.vue';

@Component({
	components: {
		antiepidemic: () => import('./modules/GoverScreenAntiepidemic.vue'),
		dpMap,
		barEchartDate,
		pieEchart
	}
})
export default class governmentScreen extends Vue {

	private timeNow: any = ''; // 大屏右上角实时时间

	private time: number = null; // goTime 时间定时器，需要在销毁组件时清除
	
	private groupCodeList = [];	// 左上角 地图 树结构

	private showCitySelection: boolean = false;	// 树结构 隐藏/显示 控制
	
	private changeName: string = "";	// 当前 树结构 选择地区的 名称

	private authorityAdcode: number = null; //当前账户的地图权限

	private authorityName: string = null; // 当前账户权限对应的名称

	private changeCity: string = "-1";
	
	private TitleName:any = '中国'
	
	private curentAdcode: number = 0;	// 当前区域的 adcode
	
	private cancelTokenNowCount = null;
	private pieEcharDataNowCount: any = {};		// 实时采集数量

	private cancelTokenYangzhi = null;
	private echartDataYangZhi: any = {};	// 养殖规模统计 

	private cancelTokenChulan = null;
	private echartDataChuLan: any = {};		// 出栏数量 

	private cancelTokenCount = null;
	private echartDataCount: any = {};		// 头数 

	private cancelTokenTaskStock = null;
	private pieEcharDataTakeStock: any = {};	// 惠普金融活体贷款

	private cancelTokenBaoxian = null;
	private echartDataBaoxian : any = {};	// 保险投保 

	private cancelTokenHealth = null;
	private echartHealthWarning : any = {};	// AI健康预警 

	private cancelTokenDongjing = null;
	private echartDongJingData: any = {};	// 冻精数量

	
	private async mounted() {

		this.setScale();
		this.goTime();	// 设置时间
		await this.getGroupCode(); // 获取树结构
		// this.refreshData(); // 获取图表信息
	}

	private needScale: boolean = false;

	setScale() {
		let clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		if (clientWidth === 1280) {
			// console.log('我这边是1920的150%缩放比例，因此需要设置scale');
			this.needScale = true;
		}
	}

	private beforeDestroy(): void {
		// 清除定时器
		clearInterval(this.time);
	}
	
	private goTime() {
		this.time = window.setInterval(() => {
			let date = new Date();
			this.timeNow = Util.dateTimeUtil(date);
		}, 1000);
	}

	// 左上角返回
	private goback(): void {
		// new BasicsClass().BasicGet("/animal/web/menu/currentMenus",{},false,true,(res: any) => {
		// 	let url = res.data[0].children[0].url;
		// 	const path = this.$route.path === url ? '/homeep' : url;
		// 	// console.log('----router: ', this.$route.path, url, path)
		// 	this.$router.push(path);
		// });
		this.$router.push('/Welcome');
	}

	// 处理 树结构展开 状态
	private handleCity(item: any): void {
		// console.log('handleCity Data', item)
		this.showCitySelection = false;
		this.changeName = item.name;

		// 根据点击地区节点的ID 去匹配高德地区对应的ID，展开地图
		let id = item.gdGroupCode;
		if (item.id && item.id.toString().length === 6) {
			// console.log('表明我点击的是最后一层级，六位地区码');
			// 这个code地图是获取不到下一级区域的，所以无法获取地图节点对象，需要我们自己设置名称和点位信息
			this.$refs.dpMap.setLastLevelObj(item.name, item.longitude, item.latitude);
		}
		for (let i = Number(id).toString().length; i < 6; i += 1) {
			id += '0';
		}
		if (id === '000000') id = 100000;
		// console.log('补足id为六位，末尾加0：', id);
		// 点击了树结构，需要同步地图变化

		// this.$refs.dpMap.switch2AreaNode(Number(id), null, id);

		// console.log(this, Boolean(this.$refs.dpMap));
		let that = this;

		self();
		function self() {
			// console.log(Boolean(that), 666);
			if (that && that.$refs && that.$refs.dpMap) {
				// console.log('我成功的调用了地图组件', id);
				// if (id === 100000) id = 520000
				that.$refs.dpMap.switch2AreaNode(Number(id), null, id);
			} else {
				setTimeout(() => {
					// console.log('地图还没有加载成功，所以我要一直调用');
					self()
				}, 100)
			}
		}
	}

	// 子组件更新地区adcode
	private setCurentAdcode(adcode: number) {
		// console.log('地图组件更新了当前区域，所以我需要设置当前区域的数据', adcode);
		this.curentAdcode = adcode === 100000 ? 0 : Number(adcode.toString().replace(/(0+)\b/gi,""));
		// console.log('-------refreshdata', this.curentAdcode)
		this.refreshData();
	}

	// 子组件更新地区名称
	private setCurentAreaName(name: string): void {
		this.changeName = name;
	}

	// 地区改变，需要同时更新图表数据
	private refreshData (): void {
		this.setPieData();		// 更新 实时采集数量
		this.setYangZhiData(); // 更新 养殖规模统计
		this.setChuLanData();	// 更新 出栏数量
		this.setCountData(); // 更新 头数

		this.setJinRongPieData() // 更新 普惠金融活体贷款
		this.setBaoXianData(); // 更新 保险投保
		this.setHealthWarningData();	// 更新 AI健康预警
		this.setDongjingData();	// 更新冻精数量
	}

	// 树结构 请求接口
	private async getGroupCode() {
		await axios({
				method: 'get',
				url: '/animal/web/bigScreenGovernmentNew/governmentGroupCode',
			}).then((res: any) => {
				// console.log('新版的后台树列表接口', res)
				const data = res.data.data;
				this.groupCodeList = data;
				this.changeName = data[0].name;
				this.authorityAdcode = data[0].id;
				this.authorityName = data[0].name;
				// this.curentAdcode = data[0].id;
				let that = this;
				setTimeout(() => {
					// console.log('-----初始化地图数据');
					that.handleCity({
						gdGroupCode: data[0].id,
						name: data[0].name,
						longitude: data[0].longitude,
						latitude: data[0].latitude,
						id: data[0].id
					});
				}, 3000);
			});
		// return new Promise((resolve, reject) => {
		// 	new BasicsClass().BasicGet("/animal/web/bigScreenGovernmentNew/governmentGroupCode",{},false,true,
		// 		(res: any) => {
		// 			// console.log('新版的后台树列表接口', res)
		// 			this.groupCodeList = res.data;
		// 			this.changeName = res.data[0].name;
		// 			resolve(" ");
		// 		}
		// 	);
		// });
	}

	// 设置 养殖规模统计 数据
	async setYangZhiData(): Promise<void> {
		let _that = this;
		this.cacelFunc('cancelTokenYangzhi');
		// 写成方法调用是预留 本周/本月 操作，以免需求改变要重新改造方法，更麻烦
		let apiTestt = async function(param) {
			// console.log('/bigScreenGovernmentNew2/scaleStatistical 请求开始');
			await axios({
				method: 'get',
				url: '/animal/web/bigScreenGovernmentNew2/scaleStatistical',
				params: {
					groupCode: _that.curentAdcode,
				},
				cancelToken: new axios.CancelToken((c) => {
					// console.log('-----------ccc', c)
					_that.cancelTokenYangzhi = c;
				}),
			}).then((res: any) => {
				console.log(res)
				_that.cancelTokenYangzhi = null;
				// console.log('-------- 养殖规模统计 返回数据: ', res);
				// let data = JSON.parse(JSON.stringify(res.data.data));
				// console.log(data)
				let data:any = [
					{
						"groupCode": "5201",
						"name": "贵阳市",
						"tenNum": 500,
						"elevenToThirty": 319,
						"thirtyOneToFifty": 286,
						"fiftyOneToOneHundred": 5,
						"oneHundredAndOneToOneThousand": 12,
						"moreThanOneThousand": 5
					},
					{
						"groupCode": "5202",
						"name": "六盘水市",
						"tenNum": 478,
						"elevenToThirty": 291,
						"thirtyOneToFifty": 9,
						"fiftyOneToOneHundred": 8,
						"oneHundredAndOneToOneThousand": 19,
						"moreThanOneThousand": 8
					},
					{
						"groupCode": "5203",
						"name": "遵义市",
						"tenNum": 508,
						"elevenToThirty": 529,
						"thirtyOneToFifty": 331,
						"fiftyOneToOneHundred": 220,
						"oneHundredAndOneToOneThousand": 93,
						"moreThanOneThousand": 39
					},
					{
						"groupCode": "5204",
						"name": "安顺市",
						"tenNum": 248,
						"elevenToThirty": 145,
						"thirtyOneToFifty": 41,
						"fiftyOneToOneHundred": 7,
						"oneHundredAndOneToOneThousand": 1,
						"moreThanOneThousand": 5
					},
					{
						"groupCode": "5205",
						"name": "毕节市",
						"tenNum": 154,
						"elevenToThirty": 457,
						"thirtyOneToFifty": 133,
						"fiftyOneToOneHundred": 3,
						"oneHundredAndOneToOneThousand": 1,
						"moreThanOneThousand": 1
					},
					{
						"groupCode": "5206",
						"name": "铜仁市",
						"tenNum": 7,
						"elevenToThirty": 784,
						"thirtyOneToFifty": 152,
						"fiftyOneToOneHundred": 451,
						"oneHundredAndOneToOneThousand": 15,
						"moreThanOneThousand": 4
					},
					{
						"groupCode": "5223",
						"name": "黔西南",
						"tenNum": 246,
						"elevenToThirty": 178,
						"thirtyOneToFifty": 578,
						"fiftyOneToOneHundred": 79,
						"oneHundredAndOneToOneThousand": 49,
						"moreThanOneThousand": 27
					},
					{
						"groupCode": "5226",
						"name": "黔东南",
						"tenNum": 1,
						"elevenToThirty": 398,
						"thirtyOneToFifty": 145,
						"fiftyOneToOneHundred": 784,
						"oneHundredAndOneToOneThousand": 14,
						"moreThanOneThousand": 7
					},
					{
						"groupCode": "5227",
						"name": "黔南",
						"tenNum": 1,
						"elevenToThirty": 457,
						"thirtyOneToFifty": 150,
						"fiftyOneToOneHundred": 178,
						"oneHundredAndOneToOneThousand": 75,
						"moreThanOneThousand": 15
					}
				]
				if (_that.curentAdcode === 0 || true) {
					// console.log('----全国的数据，需要过滤，不然饼图放不下');
					data = data.filter(item => {
						// 把有数据的返回
						let count = 0;
						count = item.elevenToThirty + item.fiftyOneToOneHundred + item.moreThanOneThousand
							+	item.oneHundredAndOneToOneThousand + item.tenNum + item.thirtyOneToFifty;
						// console.log(Boolean(count), count);
						return Boolean(count)
					})

					if (data.length === 0) {
						// 如果一个数据都没有，取前十个，不然图例太多了
						data = res.data.data.slice(0);
					}
				}
				// console.log('过滤后的数据', data)

				data.forEach(item => {
					
					let obj = {
						tenNum: '10头',
						elevenToThirty: '10-30头',
						thirtyOneToFifty: '31-50头',
						fiftyOneToOneHundred: '50-100头',
						oneHundredAndOneToOneThousand: '100-1000头',
						moreThanOneThousand: '1000头以上',
					}
					let children = {};
					
					// 过滤为0的字段
					Object.keys(obj).forEach(sub => {
						if (item[sub]) children[obj[sub]] = item[sub]
					})

					param.push({
						value: item.elevenToThirty + item.fiftyOneToOneHundred + item.moreThanOneThousand + item.oneHundredAndOneToOneThousand + item.tenNum + item.thirtyOneToFifty,
						// name: item.name.length > 5 ? item.name.slice(0, 4) + '...' : item.name,
						name: item.name,
						children
					})
				});
			}).catch(err => {
				// console.log('---------- 养殖规模统计 请求出错', err)
			});
		}

		let pieData = [];
		await apiTestt(pieData);
		// console.log('养殖规模数据 Data: ', pieData)

		// 养殖规模 本来是柱状体，后来改成了饼图
		let echartDataYangZhi = {
			// xLength: this.needScale ? 36 : 38, 					// 图例超过多少个滚动效果
			changeDate: false,		// 不分 周/月
			endValue: 3,					// 最多显示4个
			delayTime: 1600,			// 延迟加载
			yName: '户',
			autoTooltip: false,		// 不自动展示
			showInnerPercent: true,	// 饼图显示inner百分比
			highLightPercent: true,	// 高亮显示的时候展示百分比数字
			pieMode: 'mode2',
			pieData: pieData
		}

		this.echartDataYangZhi = echartDataYangZhi;

		// 饼图数据结构
		// let pieData = [
		// 	{ value: 3265, pp: ' 占比：14%', name: '扎鲁特旗', children: { '10头': 4200, '10-30头': 3000, '31-50头': 6000, '50-100头': 700, '100-1000头': 3222, '1000头以上': 632, } },
		// ]

		// 柱状图数据结构
		// echartDataObj: {
		// 	'month': [
		// 		['category', '10头', '10-30头', '31-50头', '50-100头', '100-1000头', '1000头以上'],
		// 		['扎鲁特旗', 4200,3000,6000,700,3222,632,3],
		// 	]
		// },
	}
	
	// 设置 出栏数量 数据
	async setChuLanData(): Promise<void> {
		this.cacelFunc('cancelTokenChulan');
		
		let _that = this;
		let apiTest = async function(type, param) {
			await axios({
				method: 'get',
				url: '/animal/web/bigScreenGovernmentNew2/outColumnList',
				params: {
					groupCode: _that.curentAdcode,
					type: type,
				},
				cancelToken: new axios.CancelToken((c) => {
					// console.log('-----------ccc', c)
					_that.cancelTokenChulan = c;
				}),
			}).then((res: any) => {
				_that.cancelTokenChulan = null;

				let outColumnTypeList:any = [
					{
						"typeId": "607970402957787136",
						"typeName": "基础母牛"
					},
					{
						"typeId": "607970402957787136",
						"typeName": "后备母牛"
					},
					{
						"typeId": "607970402957787136",
						"typeName": "大育成牛"
					},
					{
						"typeId": "607970402957787136",
						"typeName": "架子牛"
					},
					{
						"typeId": "607970402957787136",
						"typeName": "青年牛"
					},
					{
						"typeId": "607970402957787136",
						"typeName": "育肥牛"
					},
					{
						"typeId": "607970402957787136",
						"typeName": "犊牛"
					},
				]

				let outColumnNewOutputList:any[]= [
					{
						"sum": 2,
						"groupCode": "5203",
						"name": "遵义市",
						"outColumnDtoList": [
							{
								"num": 15455,
								"typeId": "607970402957787136",
								"typeName": "基础母牛",
								"groupCode": "520327107"
							},
							{
								"num": 2142,
								"typeId": "607970402957787136",
								"typeName": "后备母牛",
								"groupCode": "520327107"
							},
							{
								"num": 3540,
								"typeId": "607970402957787136",
								"typeName": "育肥牛",
								"groupCode": "520327107"
							},
							{
								"num": 2512,
								"typeId": "607970402957787136",
								"typeName": "架子牛",
								"groupCode": "520327107"
							},
							{
								"num": 8585,
								"typeId": "607970402957787136",
								"typeName": "犊牛",
								"groupCode": "520327107"
							},
							{
								"num": 1501,
								"typeId": "607970402957787136",
								"typeName": "大育成牛",
								"groupCode": "520327107"
							},
							{
								"num": 1840,
								"typeId": "607970402957787136",
								"typeName": "青年牛",
								"groupCode": "520327107"
							},
						]
					},
					{
						"sum": 0,
						"groupCode": "5201",
						"name": "贵阳市",
						"outColumnDtoList": [
							{
								"num": 5142,
								"typeId": "607970402957787136",
								"typeName": "基础母牛",
								"groupCode": "520327107"
							},
							{
								"num": 784,
								"typeId": "607970402957787136",
								"typeName": "后备母牛",
								"groupCode": "520327107"
							},
							{
								"num": 1246,
								"typeId": "607970402957787136",
								"typeName": "育肥牛",
								"groupCode": "520327107"
							},
							{
								"num": 1045,
								"typeId": "607970402957787136",
								"typeName": "架子牛",
								"groupCode": "520327107"
							},
							{
								"num": 2540,
								"typeId": "607970402957787136",
								"typeName": "犊牛",
								"groupCode": "520327107"
							},
							{
								"num": 810,
								"typeId": "607970402957787136",
								"typeName": "大育成牛",
								"groupCode": "520327107"
							},
							{
								"num": 542,
								"typeId": "607970402957787136",
								"typeName": "青年牛",
								"groupCode": "520327107"
							},
						]
					},
					{
						"sum": 0,
						"groupCode": "5202",
						"name": "六盘水市",
						"outColumnDtoList": [
							{
								"num": 6105,
								"typeId": "607970402957787136",
								"typeName": "基础母牛",
								"groupCode": "520327107"
							},
							{
								"num": 912,
								"typeId": "607970402957787136",
								"typeName": "后备母牛",
								"groupCode": "520327107"
							},
							{
								"num": 1541,
								"typeId": "607970402957787136",
								"typeName": "育肥牛",
								"groupCode": "520327107"
							},
							{
								"num": 1560,
								"typeId": "607970402957787136",
								"typeName": "架子牛",
								"groupCode": "520327107"
							},
							{
								"num": 2480,
								"typeId": "607970402957787136",
								"typeName": "犊牛",
								"groupCode": "520327107"
							},
							{
								"num": 789,
								"typeId": "607970402957787136",
								"typeName": "大育成牛",
								"groupCode": "520327107"
							},
							{
								"num": 542,
								"typeId": "607970402957787136",
								"typeName": "青年牛",
								"groupCode": "520327107"
							},
						]
					},
					{
						"sum": 0,
						"groupCode": "5204",
						"name": "安顺市",
						"outColumnDtoList": [
							{
								"num": 3184,
								"typeId": "607970402957787136",
								"typeName": "基础母牛",
								"groupCode": "520327107"
							},
							{
								"num": 574,
								"typeId": "607970402957787136",
								"typeName": "后备母牛",
								"groupCode": "520327107"
							},
							{
								"num": 1246,
								"typeId": "607970402957787136",
								"typeName": "育肥牛",
								"groupCode": "520327107"
							},
							{
								"num": 1451,
								"typeId": "607970402957787136",
								"typeName": "架子牛",
								"groupCode": "520327107"
							},
							{
								"num": 2840,
								"typeId": "607970402957787136",
								"typeName": "犊牛",
								"groupCode": "520327107"
							},
							{
								"num": 578,
								"typeId": "607970402957787136",
								"typeName": "大育成牛",
								"groupCode": "520327107"
							},
							{
								"num": 254,
								"typeId": "607970402957787136",
								"typeName": "青年牛",
								"groupCode": "520327107"
							},
						]
					},
					{
						"sum": 0,
						"groupCode": "5205",
						"name": "毕节市",
						"outColumnDtoList": [
							{
								"num": 1582,
								"typeId": "607970402957787136",
								"typeName": "基础母牛",
								"groupCode": "520327107"
							},
							{
								"num": 579,
								"typeId": "607970402957787136",
								"typeName": "后备母牛",
								"groupCode": "520327107"
							},
							{
								"num": 2504,
								"typeId": "607970402957787136",
								"typeName": "育肥牛",
								"groupCode": "520327107"
							},
							{
								"num": 1856,
								"typeId": "607970402957787136",
								"typeName": "架子牛",
								"groupCode": "520327107"
							},
							{
								"num": 1540,
								"typeId": "607970402957787136",
								"typeName": "犊牛",
								"groupCode": "520327107"
							},
							{
								"num": 454,
								"typeId": "607970402957787136",
								"typeName": "大育成牛",
								"groupCode": "520327107"
							},
							{
								"num": 1054,
								"typeId": "607970402957787136",
								"typeName": "青年牛",
								"groupCode": "520327107"
							},
						]
					},
					{
						"sum": 0,
						"groupCode": "5206",
						"name": "铜仁市",
						"outColumnDtoList": [
							{
								"num": 3154,
								"typeId": "607970402957787136",
								"typeName": "基础母牛",
								"groupCode": "520327107"
							},
							{
								"num": 645,
								"typeId": "607970402957787136",
								"typeName": "后备母牛",
								"groupCode": "520327107"
							},
							{
								"num": 2709,
								"typeId": "607970402957787136",
								"typeName": "育肥牛",
								"groupCode": "520327107"
							},
							{
								"num": 1231,
								"typeId": "607970402957787136",
								"typeName": "架子牛",
								"groupCode": "520327107"
							},
							{
								"num": 3655,
								"typeId": "607970402957787136",
								"typeName": "犊牛",
								"groupCode": "520327107"
							},
							{
								"num": 841,
								"typeId": "607970402957787136",
								"typeName": "大育成牛",
								"groupCode": "520327107"
							},
							{
								"num": 1421,
								"typeId": "607970402957787136",
								"typeName": "青年牛",
								"groupCode": "520327107"
							},
						]
					},
					{
						"sum": 0,
						"groupCode": "5223",
						"name": "黔西南",
						"outColumnDtoList": [
							{
								"num": 6142,
								"typeId": "607970402957787136",
								"typeName": "基础母牛",
								"groupCode": "520327107"
							},
							{
								"num": 1145,
								"typeId": "607970402957787136",
								"typeName": "后备母牛",
								"groupCode": "520327107"
							},
							{
								"num": 2594,
								"typeId": "607970402957787136",
								"typeName": "育肥牛",
								"groupCode": "520327107"
							},
							{
								"num": 1815,
								"typeId": "607970402957787136",
								"typeName": "架子牛",
								"groupCode": "520327107"
							},
							{
								"num": 3564,
								"typeId": "607970402957787136",
								"typeName": "犊牛",
								"groupCode": "520327107"
							},
							{
								"num": 154,
								"typeId": "607970402957787136",
								"typeName": "大育成牛",
								"groupCode": "520327107"
							},
							{
								"num": 945,
								"typeId": "607970402957787136",
								"typeName": "青年牛",
								"groupCode": "520327107"
							},
						]
					},
					{
						"sum": 0,
						"groupCode": "5226",
						"name": "黔东南",
						"outColumnDtoList": [
							{
								"num": 5872,
								"typeId": "607970402957787136",
								"typeName": "基础母牛",
								"groupCode": "520327107"
							},
							{
								"num": 1284,
								"typeId": "607970402957787136",
								"typeName": "后备母牛",
								"groupCode": "520327107"
							},
							{
								"num": 3017,
								"typeId": "607970402957787136",
								"typeName": "育肥牛",
								"groupCode": "520327107"
							},
							{
								"num": 1655,
								"typeId": "607970402957787136",
								"typeName": "架子牛",
								"groupCode": "520327107"
							},
							{
								"num": 3840,
								"typeId": "607970402957787136",
								"typeName": "犊牛",
								"groupCode": "520327107"
							},
							{
								"num": 474,
								"typeId": "607970402957787136",
								"typeName": "大育成牛",
								"groupCode": "520327107"
							},
							{
								"num": 1459,
								"typeId": "607970402957787136",
								"typeName": "青年牛",
								"groupCode": "520327107"
							},
						]
					},
					{
						"sum": 0,
						"groupCode": "5227",
						"name": "黔南",
						"outColumnDtoList": [
							{
								"num": 6587,
								"typeId": "607970402957787136",
								"typeName": "基础母牛",
								"groupCode": "520327107"
							},
							{
								"num": 1097,
								"typeId": "607970402957787136",
								"typeName": "后备母牛",
								"groupCode": "520327107"
							},
							{
								"num": 2971,
								"typeId": "607970402957787136",
								"typeName": "育肥牛",
								"groupCode": "520327107"
							},
							{
								"num": 1751,
								"typeId": "607970402957787136",
								"typeName": "架子牛",
								"groupCode": "520327107"
							},
							{
								"num": 3250,
								"typeId": "607970402957787136",
								"typeName": "犊牛",
								"groupCode": "520327107"
							},
							{
								"num": 545,
								"typeId": "607970402957787136",
								"typeName": "大育成牛",
								"groupCode": "520327107"
							},
							{
								"num": 1785,
								"typeId": "607970402957787136",
								"typeName": "青年牛",
								"groupCode": "520327107"
							},
						]
					}
				]

				outColumnTypeList.forEach(item => {
					param[0].push(item.typeName)
				})
				if (!outColumnTypeList.length) {
					param[0].push('基础母牛', '架子牛', '肉牛', '犊牛');
				}

				// console.log('-------- 出栏数量 返回数据: ', res);
				outColumnNewOutputList.forEach(item => {
					// console.log('--此地区数量', item);
					let arr = [item.name];
					
					// item.outColumnDtoList.forEach(sub => {
					// 	arr.push(sub.num);
					// })

					for (let i = 1; i < param[0].length; i += 1) {
						let flag = true;
						
						item.outColumnDtoList.forEach(sub => {
							if (param[0][i] === sub.typeName) {
								flag = false;
								arr.push(sub.num);
							}
						});

						if (flag) {
							arr.push(0);
						}
					}

					if (arr.length === 1) {
						[0,0,0,0].forEach(sub => {
							arr.push(sub);
						})
					}
					param.push(arr);
				});
				// console.log('转换后的param：', param)
			}).catch(err => {
				// console.log(err)
			});
		}

		// [['category', '基础母牛', '架子牛', '肉牛', '犊牛']]
		let month = [['category']];
		let week = [['category']];

		await apiTest(3, month);
		await apiTest(2, week);

		let echartDataChuLan = {
			barColor: ['#04D1BD', '#0352DA', '#0EA9E8', '#523BFF', '#A772FA', '#CE9E4D', '#024FEB', '#0EA9E8'],
			delayTime: 1000,
			changeDate: true,
			barWidth: 10,
			echartDataObj: {
				month,
				week
			},
		};

		// console.log('echartDataChuLan 过滤后的图表数据', echartDataChuLan)

		this.echartDataChuLan = echartDataChuLan;

		// 数据结构
		// 'month': [
		// 	['category', '基础母牛', '架子牛', '肉牛', '犊牛'],
		// 	['扎鲁特旗', 421,130,633,730],
		// ],
		// 'week': [
		// 	['category', '基础母牛', '架子牛', '肉牛', '犊牛'],
		// 	['扎鲁特旗', 21,13,63,3],
		// ]
	}


	// 设置 头数 数据
	async setCountData(): Promise<void> {
		let _that = this;
		this.cacelFunc('cancelTokenCount');
		let apiTest = async function(type, param) {
			await axios({
				method: 'get',
				url: '/animal/web/bigScreenGovernmentNew2/numAndbreedStatistical',
				params: {
					groupCode: _that.curentAdcode,
					type: type,
				},
				cancelToken: new axios.CancelToken((c) => {
					// console.log('-----------ccc', c)
					_that.cancelTokenCount = c;
				}),
			}).then((res: any) => {
				_that.cancelTokenCount = null;
				// console.log('-------- 头数 返回数据: ', res);

				let numAndbreedStatisticalArr:any = [
					{
						"sum": 8124,
						"groupCode": "5203",
						"name": "遵义市",
						"twoHundredToThreeHundred": 6948,
						"threeHundredToFourHundred": 1945,
						"fourKgUpBaseCowHundred": 11594,
						"fourKgUpFatCowHundred": 2918
					},
					{
						"sum": 350,
						"groupCode": "5226",
						"name": "黔东南",
						"twoHundredToThreeHundred": 2980,
						"threeHundredToFourHundred": 1154,
						"fourKgUpBaseCowHundred": 3851,
						"fourKgUpFatCowHundred": 1571
					},
					{
						"sum": 247,
						"groupCode": "5204",
						"name": "安顺市",
						"twoHundredToThreeHundred": 2481,
						"threeHundredToFourHundred": 957,
						"fourKgUpBaseCowHundred": 2481,
						"fourKgUpFatCowHundred": 914
					},
					{
						"sum": 0,
						"groupCode": "5201",
						"name": "贵阳市",
						"twoHundredToThreeHundred": 2184,
						"threeHundredToFourHundred": 979,
						"fourKgUpBaseCowHundred": 2841,
						"fourKgUpFatCowHundred": 1054
					},
					{
						"sum": 0,
						"groupCode": "5202",
						"name": "六盘水市",
						"twoHundredToThreeHundred": 1054,
						"threeHundredToFourHundred": 451,
						"fourKgUpBaseCowHundred": 1987,
						"fourKgUpFatCowHundred": 875
					},
					{
						"sum": 0,
						"groupCode": "5205",
						"name": "毕节市",
						"twoHundredToThreeHundred": 651,
						"threeHundredToFourHundred": 215,
						"fourKgUpBaseCowHundred": 1486,
						"fourKgUpFatCowHundred": 874
					},
					{
						"sum": 0,
						"groupCode": "5206",
						"name": "铜仁市",
						"twoHundredToThreeHundred": 482,
						"threeHundredToFourHundred": 368,
						"fourKgUpBaseCowHundred": 1089,
						"fourKgUpFatCowHundred": 775
					},
					{
						"sum": 0,
						"groupCode": "5223",
						"name": "黔西南",
						"twoHundredToThreeHundred": 851,
						"threeHundredToFourHundred": 321,
						"fourKgUpBaseCowHundred": 415,
						"fourKgUpFatCowHundred": 325
					},
					{
						"sum": 0,
						"groupCode": "5227",
						"name": "黔南",
						"twoHundredToThreeHundred": 1241,
						"threeHundredToFourHundred": 254,
						"fourKgUpBaseCowHundred": 2456,
						"fourKgUpFatCowHundred": 1483
					}
				]

				numAndbreedStatisticalArr.forEach(item => param.push(
					[
						item.name,
						// item.threeHundred,
						item.twoHundredToThreeHundred,
						item.threeHundredToFourHundred,
						item.fourKgUpBaseCowHundred,
						item.fourKgUpFatCowHundred
					])
				);
					// [
					// 	item.name,
					// 	// item.threeHundred,
					// 	item.threeHundredToFourHundred,
					// 	item.fourHundredToFiveHundred,
					// 	item.fiveHundredToSixHundred, item.sixHundredToSevenHundred,
					// 	item.sevenHundredToEightHundred, item.moreThanEightHundred
					// ]
			}).catch(err => {
				// console.log(err)
			});
		}

		// let month = [['category', '300-400斤', '400-500斤', '500-600斤', '600-700斤', '700-800斤', '800斤以上']];
		let month = [['category', '200-300公斤犊牛', '300-400公斤架子牛', '400公斤以上基础母牛', '400公斤以上育肥牛']];
		let week = [['category', '200-300公斤犊牛', '300-400公斤架子牛', '400公斤以上基础母牛', '400公斤以上育肥牛']];

		apiTest(3, month);
		// await apiTest(2, week);

		let echartDataCount = {
			changeDate: false,		// 不分 周/月
			gridTop: 10,
			endValue: 3,	// 最多显示4个
			delayTime: 1400,
			echartDataObj: {
				month,
				week
			},
		};

		this.echartDataCount = echartDataCount;

		// 数据结构
		// 'month': [
		// 	['category', '300斤以下', '300-500斤', '500-600斤', '600-700斤', '700-800斤', '800斤以上'],
		// 	['扎鲁特旗', 1421,1230,3633,4730,434,122],
		// ],
		// 'week': [
		// 	['category', '300斤以下', '300-500斤', '500-600斤', '600-700斤', '700-800斤', '800斤以上'],
		// 	['扎鲁特旗', 21,13,63,3,12,35],
		// ]
	}
	
	// 设置 普惠金融活体贷款 数据
	async setJinRongPieData(): Promise<void> {
		let _that = this;
		this.cacelFunc('cancelTokenTaskStock');
		// 写成方法调用是预留 本周/本月 操作，以免需求改变要重新改造方法，更麻烦
		let apiTestt = async function(param) {
			await axios({
				method: 'get',
				url: '/animal/web/bigScreenGovernmentNew2/loanData',
				params: {
					groupCode: _that.curentAdcode,
				},
				cancelToken: new axios.CancelToken((c) => {
					// console.log('-----------ccc', c)
					_that.cancelTokenTaskStock = c;
				}),
			}).then((res: any) => {
				_that.cancelTokenTaskStock = null;
				// console.log('-------- 普惠金融活体贷款 返回数据: ', res);

				let data = JSON.parse(JSON.stringify(res.data.data));
				// console.log(data)
				data = [
					{
						"groupCode": "5201",
						"name": "贵阳市",
						"fifty": 0,
						"fiftyToOneHundred": 702,
						"oneHundredToThreeHundred": 12,
						"threeHundredToFiveHundred": 0,
						"moreThanFiveHundred": 0
					},
					{
						"groupCode": "5202",
						"name": "六盘水市",
						"fifty": 0,
						"fiftyToOneHundred": 305,
						"oneHundredToThreeHundred": 8,
						"threeHundredToFiveHundred": 1,
						"moreThanFiveHundred": 0
					},
					{
						"groupCode": "5203",
						"name": "遵义市",
						"fifty": 0,
						"fiftyToOneHundred": 2195,
						"oneHundredToThreeHundred": 13,
						"threeHundredToFiveHundred": 4,
						"moreThanFiveHundred": 1
					},
					{
						"groupCode": "5204",
						"name": "安顺市",
						"fifty": 0,
						"fiftyToOneHundred": 741,
						"oneHundredToThreeHundred": 0,
						"threeHundredToFiveHundred": 0,
						"moreThanFiveHundred": 0
					},
					{
						"groupCode": "5205",
						"name": "毕节市",
						"fifty": 0,
						"fiftyToOneHundred": 215,
						"oneHundredToThreeHundred": 0,
						"threeHundredToFiveHundred": 0,
						"moreThanFiveHundred": 0
					},
					{
						"groupCode": "5206",
						"name": "铜仁市",
						"fifty": 0,
						"fiftyToOneHundred": 192,
						"oneHundredToThreeHundred": 3,
						"threeHundredToFiveHundred": 0,
						"moreThanFiveHundred": 0
					},
					{
						"groupCode": "5223",
						"name": "黔西南",
						"fifty": 0,
						"fiftyToOneHundred": 291,
						"oneHundredToThreeHundred": 1,
						"threeHundredToFiveHundred": 0,
						"moreThanFiveHundred": 0
					},
					{
						"groupCode": "5226",
						"name": "黔东南",
						"fifty": 0,
						"fiftyToOneHundred": 982,
						"oneHundredToThreeHundred": 5,
						"threeHundredToFiveHundred": 0,
						"moreThanFiveHundred": 0
					},
					{
						"groupCode": "5227",
						"name": "黔南",
						"fifty": 0,
						"fiftyToOneHundred": 387,
						"oneHundredToThreeHundred": 0,
						"threeHundredToFiveHundred": 2,
						"moreThanFiveHundred": 0
					}
				]

				if (_that.curentAdcode === 0 || true) {
					// console.log('----全国的数据，需要过滤，不然饼图放不下');
					data = data.filter(item => {
						// 把有数据的返回
						let count = 0;
						count = item.fifty + item.fiftyToOneHundred + item.oneHundredToThreeHundred
							+	item.threeHundredToFiveHundred + item.moreThanFiveHundred;
						// console.log(Boolean(count), count);
						return Boolean(count)
					})

					if (data.length === 0) {
						// 如果一个数据都没有，取前十个，不然图例太多了
						data = data.slice(0);
					}
				}
				// console.log('过滤后的数据', data)

				data.forEach(item => {
					
					let obj = {
						fifty: '50万以下',
						fiftyToOneHundred: '50-100万',
						oneHundredToThreeHundred: '100-300万',
						threeHundredToFiveHundred: '300-500万',
						moreThanFiveHundred: '500万以上',
					}
					let children = {};
					
					// 过滤为0的字段
					Object.keys(obj).forEach(sub => {
						if (item[sub]) children[obj[sub]] = item[sub]
					})

					param.push(
						{
							name: item.name,
							// name: item.name.length > 5 ? item.name.slice(0, 4) + '...' : item.name,
							value: item.fifty + item.fiftyToOneHundred + item.oneHundredToThreeHundred
								+ item.threeHundredToFiveHundred + item.moreThanFiveHundred, 
							children
						})
				});
			}).catch(err => {
				// console.log('---------- 普惠金融活体贷款 请求出错', err)
			});
		}

		let pieData = [];
		await apiTestt(pieData);
		// console.log('------普惠金融活体贷款 配置后的数据', pieData);

		let pieEcharDataTakeStock = {
			delayTime: 1800,
			pieName: '',
			routerPath: '/loanapproval',
			highLightPercent: true,
			autoTooltip: false,
			showInnerPercent: true,
			pieMode: 'mode2',
			// xLength: this.needScale ? 12 : 14, 					// 图例超过多少个滚动效果
			pieData
		}

		this.pieEcharDataTakeStock = pieEcharDataTakeStock;

		// 数据结构
		// pieData: [ { value: 3265, name: '扎鲁特旗', children: { '50万以下': '13户', '100-300万': '7户', '500万以下': '3户', } } ],
	}

	// 设置 保险投保 数据
	async setBaoXianData(): void {
		// 定义函数
		let _that = this;
		this.cacelFunc('cancelTokenBaoxian');
		let apiTest = async function(param) {
			await axios({
				method: 'get',
				url: '/animal/web/bigScreenGovernmentNew2/insuranceData',
				params: {
					groupCode: _that.curentAdcode
				},
				cancelToken: new axios.CancelToken((c) => {
					// console.log('-----------ccc', c)
					_that.cancelTokenBaoxian = c;
				}),
			}).then((res: any) => {
				_that.cancelTokenBaoxian = null;
				// console.log('-------- 保险投保 返回数据: ', res);

				let insuranceDataArr:any =[
					{
						"sum": 229,
						"groupCode": "5226",
						"name": "黔东南",
						"policyNum": 14920,
						"businessNum": 12046
					},
					{
						"sum": 0,
						"groupCode": "5201",
						"name": "贵阳市",
						"policyNum": 10924,
						"businessNum": 9547
					},
					{
						"sum": 0,
						"groupCode": "5202",
						"name": "六盘水市",
						"policyNum": 12059,
						"businessNum": 11094
					},
					{
						"sum": 0,
						"groupCode": "5203",
						"name": "遵义市",
						"policyNum": 29786,
						"businessNum": 22404
					},
					{
						"sum": 0,
						"groupCode": "5204",
						"name": "安顺市",
						"policyNum": 7881,
						"businessNum": 5481
					},
					{
						"sum": 0,
						"groupCode": "5205",
						"name": "毕节市",
						"policyNum": 11430,
						"businessNum": 9425
					},
					{
						"sum": 0,
						"groupCode": "5206",
						"name": "铜仁市",
						"policyNum": 11540,
						"businessNum": 8753
					},
					{
						"sum": 0,
						"groupCode": "5223",
						"name": "黔西南",
						"policyNum": 14922,
						"businessNum": 11548
					},
					{
						"sum": 0,
						"groupCode": "5227",
						"name": "黔南",
						"policyNum": 14804,
						"businessNum": 11540
					}
				]

				insuranceDataArr.forEach(item => param.push([item.name, item.policyNum, item.businessNum]));
			}).catch(err => {
				// console.log(err)
			});
		}

		let month = [['category', '政策性保险', '商业性保险']];
		let week = [['category', '政策性保险', '商业性保险']];

		await apiTest(month);

		let echartDataBaoxian = {
			changeDate: false,	// 没有 月/周
			legendTop: 1,
			legendRight: 100,
			routerPath: '/insureRecord',
			barColor: ['#523BFF', '#34DEF7'],
			endValue: 3,	// 最大显示4个
			delayTime: 2200,
			echartDataObj: {
				month,
				week
			}
		};

		this.echartDataBaoxian = echartDataBaoxian;

		// 数据结构
		// 'month': [
		// 	['category', '政策性保险', '商业性保险'],
		// 	['扎鲁特旗', 1421,130],
		// ],
	}

	// 设置 AI健康预警 数据
	async setHealthWarningData(): Promise<void> {
		let _that = this;
		this.cacelFunc('cancelTokenHealth');
		let apiTest = async function(type, param) {
			await axios({
				method: 'get',
				url: '/animal/web/bigScreenGovernmentNew/healthAlarmList',
				params: {
					groupCode: _that.curentAdcode,
					type: type,
				},
				cancelToken: new axios.CancelToken((c) => {
					// console.log('-----------ccc', c)
					_that.cancelTokenHealth = c;
				}),
			}).then((res: any) => {
				_that.cancelTokenHealth = null;
				// console.log('--------AI健康预警返回数据: ', res);

				let healthListArr:any = [
					{
						"sum": 0,
						"groupCode": "5201",
						"name": "贵阳市",
						"pigCount": 0,
						"cowCount": 21,
						"sheepCount": 0
					},
					{
						"sum": 0,
						"groupCode": "5202",
						"name": "六盘水市",
						"pigCount": 0,
						"cowCount": 15,
						"sheepCount": 0
					},
					{
						"sum": 0,
						"groupCode": "5203",
						"name": "遵义市",
						"pigCount": 0,
						"cowCount": 148,
						"sheepCount": 0
					},
					{
						"sum": 0,
						"groupCode": "5204",
						"name": "安顺市",
						"pigCount": 0,
						"cowCount": 74,
						"sheepCount": 0
					},
					{
						"sum": 0,
						"groupCode": "5205",
						"name": "毕节市",
						"pigCount": 0,
						"cowCount": 39,
						"sheepCount": 0
					},
					{
						"sum": 0,
						"groupCode": "5206",
						"name": "铜仁市",
						"pigCount": 0,
						"cowCount": 17,
						"sheepCount": 0
					},
					{
						"sum": 0,
						"groupCode": "5223",
						"name": "黔西南",
						"pigCount": 0,
						"cowCount": 14,
						"sheepCount": 0
					},
					{
						"sum": 0,
						"groupCode": "5226",
						"name": "黔东南",
						"pigCount": 0,
						"cowCount": 24,
						"sheepCount": 0
					},
					{
						"sum": 0,
						"groupCode": "5227",
						"name": "黔南",
						"pigCount": 0,
						"cowCount": 87,
						"sheepCount": 0
					}
				]

				healthListArr.forEach(item => param.push([item.name, item.cowCount]));
			}).catch(err => {
				// console.log(err)
			});
		}

		let week = [['category', 'AI预警']];
		let month = [['category', 'AI预警']];

		await apiTest(2, week);
		await apiTest(3, month);

		let echartHealthWarning = {
			changeDate: true,
			routerPath: '/healthWarning',
			showLegend: false, // 不显示legend图例
			barColor: ['#FF9000'],
			endValue: 7,	// 最大显示8个
			delayTime: 2400,
			barMode: 'mode2',
			echartDataObj: {
				'month': month,
				'week': week,
			}
		}

		this.echartHealthWarning = echartHealthWarning;

		// 数据结构
		// let echartDataObj = {
		// 	'month': [
		// 		['category', 'AI预警'],
		// 		['扎鲁特旗', 1421],
		// 	],
		// 	'week': [
		// 		['category', 'AI预警'],
		// 		['扎鲁特旗', 421],
		// 	],
		// }
	}

	// 设置 冻精数量 数据
	async setDongjingData(): Promise<void> {
		let _that = this;
		this.cacelFunc('cancelTokenDongjing');
		let apiTest = async function(param) {
			await axios({
				method: 'get',
				url: '/animal/web/bigScreenGovernmentNew2/frozenSemenStatistical',
				params: { groupCode: _that.curentAdcode },
				cancelToken: new axios.CancelToken((c) => {
					// console.log('-----------ccc', c)
					_that.cancelTokenDongjing = c;
				}),
			}).then((res: any) => {
				_that.cancelTokenDongjing = null;
				// console.log('-------- 冻精数量 返回数据: ', res);
				let frozenSemenArr:any =[
					{
						"sum": 0,
						"groupCode": "5201",
						"name": "贵阳市",
						"useNum": 1548,
						"issueNum": 3510
					},
					{
						"sum": 0,
						"groupCode": "5202",
						"name": "六盘水市",
						"useNum": 984,
						"issueNum": 2402
					},
					{
						"sum": 0,
						"groupCode": "5203",
						"name": "遵义市",
						"useNum": 12480,
						"issueNum": 20183
					},
					{
						"sum": 0,
						"groupCode": "5204",
						"name": "安顺市",
						"useNum": 2548,
						"issueNum": 6513
					},
					{
						"sum": 0,
						"groupCode": "5205",
						"name": "毕节市",
						"useNum": 1452,
						"issueNum": 2540
					},
					{
						"sum": 0,
						"groupCode": "5206",
						"name": "铜仁市",
						"useNum": 927,
						"issueNum": 2585
					},
					{
						"sum": 0,
						"groupCode": "5223",
						"name": "黔西南",
						"useNum": 4817,
						"issueNum": 8452
					},
					{
						"sum": 0,
						"groupCode": "5226",
						"name": "黔东南",
						"useNum": 1225,
						"issueNum": 3541
					},
					{
						"sum": 0,
						"groupCode": "5227",
						"name": "黔南",
						"useNum": 1954,
						"issueNum": 4582
					}
				]
				frozenSemenArr.forEach(item => param.push([ item.name, item.issueNum, item.useNum ]));
			}).catch(err => {
				// console.log(err)
			});
		}

		let month = [['category', '冻精发放数量', '冻精使用数量']];
		let week = [['category', '冻精发放数量', '冻精使用数量']];

		await apiTest(month);

		let echartDongJingData = {
			changeDate: false,	// 没有 月/周
			barColor: ['#9060DC', '#523BFF'],
			endValue: 4,
			yName: '支',
			delayTime: 2800,
			echartDataObj: { month, week }
		};

		this.echartDongJingData  = echartDongJingData;

		// 'month': [
		// 	['category', '冻精使用发放', '冻精使用数量'],
		// 	['扎鲁特旗', 421,330],
		// ],
	}

	private cacelFunc(funcName: string) {
		if (typeof this[funcName] === 'function') {
			this[funcName]()
		}
	}

	// 设置 实时采集数据 
	async setPieData(): Promise<void> {
		let _that = this;
		this.cacelFunc('cancelTokenNowCount');

		// 写成方法调用是预留 本周/本月 操作，以免需求改变要重新改造方法，更麻烦
		// let apiTestt = async function(param) {
		// 	await axios({
		// 		method: 'get',
		// 		url: '/animal/web/bigScreenGovernmentNew2/getTakeStockData',
		// 		params: {
		// 			groupCode: _that.curentAdcode,
		// 		},
		// 		cancelToken: new axios.CancelToken((c) => {
		// 			// console.log('-----------ccc', c)
		// 			_that.cancelTokenNowCount = c;
		// 		}),
		// 	}).then((res: any) => {
		// 		alert(1)
		// 		console.log(res.data)
		// 		_that.cancelTokenNowCount = null;
		// 		// console.log('-------- 实时采集数据 返回数据: ', res);
		// 		let obj = res.data.data.haveInventoryTypeAndCount;
		// 		const keyArr = Object.keys(obj);
				
		// 		keyArr.forEach(item => {
		// 			// if (item.includes('牛')) {
		// 			// }
		// 			param.push({value: obj[item], name: item});
		// 		});
				
		// 		// 因为如果没有数据的话，返回的是个空对象，连分类都没有，需要自己设置初始分类
		// 		if (!param.length) {
		// 			let temp = [
		// 				{ value: 0, name: '基础母牛' },
		// 				{ value: 0, name: '架子牛' },
		// 				{ value: 0, name: '肉牛' },
		// 				{ value: 0, name: '犊牛' }
		// 			];
		// 			temp.forEach(item => param.push(item));
		// 		}
		// 	}).catch(err => {
		// 		// console.log('---------- 实时采集数据 请求出错', err)
		// 	});
		// }

		let pieData = [
			{ value: 53143, name: '基础母牛' },
			{ value: 9162, name: '后备母牛' },
			{ value: 6146, name: '大育成牛' },
			{ value: 14876, name: '架子牛' },
			{ value: 21573, name: '育肥牛' },
			{ value: 9941, name: '青年牛' },
			{ value: 30294, name: '犊牛' },

		]

		// await apiTestt(pieData);
		let pieEcharDataNowCount = {
			pieName: '实时采集数量',
			showAuto: true,
			delayTime: 200,
			routerPath: '/infoRegister',
			// xLength: this.needScale ? 12 : 14, 					// 图例超过多少个滚动效果
			pieData
		}

		this.pieEcharDataNowCount = pieEcharDataNowCount;

		
	}
}
